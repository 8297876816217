.blog-img{
  width: 100%;
  height: 60vh;
}
.content2{
    text-align: center;
}
.blog-title{
    text-align: center;
}
.blog-container{
    align-items: center;
}
.blog-card{
    background-color: rgb(52, 52, 70);
    width: 90vh;
    margin-left: 55vh;
    border-radius: 20px;
}
.blog-text{
    width: 80vh;
    text-align: center;
    padding-top: 10px;
    color: white;
    margin-left: 50px;
}