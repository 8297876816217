@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap');

.outcard {
  padding: 15px;
  margin-left: 35%;
  width: 30%;
  box-shadow: 0 1px 2px 1px #044382;
  border-radius: 3px;
}

.card {
  box-shadow: 0 1px 2px 1px #044382;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background-color: #fefeff;
  height: 50px;
}



.inputs {
  text-align: left;
  margin-top: 30px;
}

label,
input,
textarea {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

label {
  margin-bottom: 4px;
  font-size: 20px;
}

label:nth-of-type(2) {
  margin-top: 12px;
  font-size: 20px;
}

input::placeholder {
  color: gray;
}

textarea {
  background: #ecf0f3;
  padding: 10px;
  padding-left: 20px;
  height: 50px;
  font-size: 20px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

input {
  background: #ecf0f3;
  padding: 10px;
  padding-left: 20px;
  height: 50px;
  font-size: 20px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

button:hover {
  box-shadow: none;
}

.btns {
  background-color: #044382;
  color: #fff;
  width: 100%;
  padding: 10px 10px;
  border-radius: 3px;
  border: none;
}

.center {
  text-align: center;
}

